import React from "react";
import { Typography, Box } from "@mui/material";
const Header = ({ title, subtitle }) => {
    return (
        <Box mb="30px">
            <Typography
                variant="h5"
                color="darkblue"
                fontWeight="bold"
                sx={{ mb: "5px", display: "flex", alignItems: "center", justifyContent: "center", margin: "20px" }}
            //             display: flex;
            // align-items: center;
            // justify-content: center;
            // margin: 20px;
            >{title}</Typography>
            <Typography variant="h6" color="darkblue">{subtitle}</Typography>
        </Box>
    );
};

export default Header;
