import { Routes, Route } from "react-router-dom";

import Form from "./scence/form";
import Login from "./scence/login/index.jsx";
import { Fragment } from 'react'
import { ProtectedRoute } from "./scence/route/protected";

function App() {        

  return (
    // <div>
    <Routes>
      {/* <Route
        path="/"
        element={
          <Fragment>
            <div className="forLogin">
              <Login />
            </div>
          </Fragment>
        }
      /> */}
      <Route
        path="/"
        element={
          <div className="app">
            <main className="content">
              <Routes>
                {/* <Route element={<ProtectedRoute />}> */}
                <Route path="/" element={<Form />} />
                {/* </Route> */}
              </Routes>
            </main>
          </div >
        }
      />
    </Routes >

    // </div>
  )
}

export default App;
