import { Box, Button, TextField } from '@mui/material'
import firebase from "../../config";
import { Formik } from 'formik'
import { Circles } from 'react-loader-spinner'
import * as yup from 'yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import Header from '../../components/Header'
import '../../index.css'
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
const database = firebase.database();


let initialValue = {
    husbandName: '',
    wifeName: '',
    flatNumber: '',
    selectFlatType: ''
    // firstName: '',
    // lastName: '',
    // email: '',
    // contact: '',
    // maritalStatus: '',
    // gender: '',
    // dateOfBirth: '',
    // userRole: '',
    // pass: '',
    // Password: '',
    // tenantPassword: '',
    // gateNumber: '',
    // flatNumber: '',
    // owerName: '',
    // owerContact: '',
    // owerflatNumber: '',
    // ownerMaterialId: '',
    // flatNumberOwer: ''
}

const bhk = [
    {
        value: 'simplex',
        label: '3BHK Simplex'
    },
    {
        value: 'duplex',
        label: '3BHK Duplex'
    }
]
let validationSchemes = yup.object().shape({
    husbandName: yup.string().required("required"),
    wifeName: yup.string(),
    flatNumber: yup.string(),
    selectFlatType: yup.string().required("required"),
})


// const materialStatus = [
//     {
//         value: 'unmarried',
//         label: 'Unmarried',
//     },
//     {
//         value: 'married',
//         label: 'Married',
//     }
// ]
// const selectGender = [
//     {
//         value: 'male',
//         label: 'Male',
//     },
//     {
//         value: 'female',
//         label: 'Female',
//     }
// ]



const Form = () => {
    let isNonMobile = useMediaQuery("(min-width:600px)");
    const [css, setCss] = useState(false)  //////////////css messages////////
    const [errmsg, setErrMsg] = useState(false)
    const [loader, setLoader] = useState(false)
    const [successmsg, setsuccessmsg] = useState(false)

    const handleFormSubmit = async (value) => {
        console.log("----------value -----------", value)
        let { flatNumber, husbandName, wifeName, selectFlatType } = value
        let obj
        if (selectFlatType === 'simplex') {
            console.log("----------simplex -----------", selectFlatType)

            obj = {
                flatNumber: flatNumber,
                husbandName: husbandName,
                wifeName: wifeName
            }
        } else if (selectFlatType === 'duplex') {
            console.log("----------duplex -----------", selectFlatType)

            obj = {
                flatNumber2: flatNumber,
                husbandName2: husbandName,
                wifeName2: wifeName
            }
        } else {
            console.log("selectFlatType", selectFlatType)
        }
        setLoader(true)
        database.ref('namePlate/').update(obj).then(() => {
            setLoader(false)
            setsuccessmsg(true);
            setTimeout(() => {
                setsuccessmsg(false)
            }, 5000);
        }).catch((error) => {
            console.log("error occure while updating", error)
            setLoader(false)
            setErrMsg(true)
            setTimeout(() => {
                setErrMsg(false)
            }, 5000);
        })
    }
    return (

        <Box m="20px" className='boxStyle'>
            <Header title="Enter Details" subtitle="" />
            {
                loader ? (


                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: "210px" }}>
                        <Circles
                            height="80"
                            width="80"
                            color="#4fa94d"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </Box>) : (
                    <>
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={initialValue}
                            validationSchema={validationSchemes}
                        >
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                                <form id='formId' onSubmit={handleSubmit} >
                                    {successmsg && (
                                        <div className='successmsg'>
                                            <p>Name Added Successfully</p>
                                        </div>
                                    )}
                                    {errmsg && (
                                        <div className='errmsg'>
                                            <p>Some Error Occure Please Try After Some Time</p>
                                        </div>
                                    )}
                                    <Box display="grid" gap="30px" gridTemplateColumns="repeat(4,minmax(0,1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }, }}
                                    >
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id='husbandName'
                                            type='text'
                                            label='Husband Name'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.husbandName}
                                            name='husbandName'
                                            error={!!touched.husbandName && !!errors.husbandName}
                                            helperText={touched.husbandName && errors.husbandName}
                                            sx={{ gridColumn: "span 4" }}

                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id='wifeName'
                                            type='text'
                                            label='Wife Name'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.wifeName}
                                            name='wifeName'
                                            error={!!touched.wifeName && !!errors.wifeName}
                                            helperText={touched.wifeName && errors.wifeName}
                                            sx={{ gridColumn: "span 4" }}

                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id='flatNumber'
                                            type='text'
                                            label='Flat Number'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.flatNumber}
                                            name='flatNumber'
                                            error={!!touched.flatNumber && !!errors.flatNumber}
                                            helperText={touched.flatNumber && errors.flatNumber}
                                            sx={{ gridColumn: "span 4" }}

                                        />
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            id='selectFlatType'
                                            type='text'
                                            label='Flat Category'
                                            select
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.selectFlatType}
                                            name='selectFlatType'
                                            error={!!touched.selectFlatType && !!errors.selectFlatType}
                                            helperText={touched.selectFlatType && errors.selectFlatType}
                                            sx={{ gridColumn: "span 4" }}
                                        >
                                            {bhk.map((gender) => (
                                                <MenuItem key={gender.value} value={gender.value}>
                                                    {gender.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {/* <TextField
                                        fullWidth
                                        variant='filled'
                                        id='email'
                                        type='email'
                                        label='Email Address'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        name='email'
                                        error={!!touched.email && !!errors.email}
                                        helperText={touched.email && errors.email}
                                        sx={{ gridColumn: "span 2" }}

                                    />
                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id='contact'
                                        type='number'
                                        label='Contact'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.contact}
                                        name='contact'
                                        error={!!touched.contact && !!errors.contact}
                                        helperText={touched.contact && errors.contact}
                                        sx={{ gridColumn: "span 2" }}

                                    />
                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id='maritalStatus'
                                        select
                                        label='Marital Status'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.maritalStatus}
                                        name='maritalStatus'
                                        error={!!touched.maritalStatus && !!errors.maritalStatus}
                                        helperText={touched.maritalStatus && errors.maritalStatus}
                                        sx={{ gridColumn: "span 2" }}
                                    >
                                        {materialStatus.map((element) => (
                                            <MenuItem key={element.value} value={element.value}>{element.label}</MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id='gender'
                                        select
                                        label='Gender'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.gender}
                                        name='gender'
                                        error={!!touched.gender && !!errors.gender}
                                        helperText={touched.gender && errors.gender}
                                        sx={{ gridColumn: "span 2" }}

                                    >
                                        {selectGender.map((gender) => (
                                            <MenuItem key={gender.value} value={gender.value}>
                                                {gender.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id='dateOfBirth'
                                        type='text'
                                        label='D.O.B'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.dateOfBirth}
                                        name='dateOfBirth'
                                        error={!!touched.dateOfBirth && !!errors.dateOfBirth}
                                        helperText={touched.dateOfBirth && errors.dateOfBirth}
                                        sx={{ gridColumn: "span 2" }}
                                    /> */}
                                    </Box>
                                    <Box display="flex" justifyContent="center" mt="20px">
                                        <Button type='submit' color="secondary" variant='contained'>
                                            Submit
                                        </Button>
                                    </Box>

                                </form>
                            )}
                        </Formik>

                    </>
                )
            }

        </Box >
    )

}

export default Form;




